import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import emailReducer from "../redux/features/email/emailSlice";
// import loomReducer from "../redux/features/loom/loomSlice";
// import filterLoomReducer from "../redux/features/loom/loomFilterSlice";
// import filterReducer from "../redux/features/auth/filterSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    email: emailReducer,
    // filter: filterReducer,
    // filterLoom: filterLoomReducer,
    // authloom: loomReducer,
  },
});
