// import React, { useState } from "react";

// const { GoogleGenerativeAI } = require("@google/generative-ai");

// const genAI = new GoogleGenerativeAI(process.env.API_KEY);

// const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

// const generationConfig = {
//   temperature: 1,
//   topP: 0.95,
//   topK: 64,
//   maxOutputTokens: 8192,
//   responseMimeType: "text/plain",
// };

// function CropAnna4() {
//   const [userInput, setUserInput] = useState("");
//   const [messages, setMessages] = useState([]);

//   const sendMessage = async () => {
//     if (!userInput) return;

//     // Display user message
//     setMessages([...messages, { sender: "User", text: userInput }]);
//     setUserInput("");

//     try {
//       //   const response = await axios.post("http://localhost:5000/api/chat", {
//       //     message: userInput,
//       //   });
//       setMessages([
//         ...messages,
//         { sender: "User", text: userInput },
//         { sender: "Bot", text: response.data.response },
//       ]);
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//   };

//   return (
//     <div className="App">
//       <h1>Simple Chatbot</h1>
//       <div id="chat-box">
//         {messages.map((msg, index) => (
//           <div
//             key={index}
//             className={msg.sender === "User" ? "user-message" : "bot-message"}
//           >
//             <strong>{msg.sender}: </strong>
//             {msg.text}
//           </div>
//         ))}
//       </div>
//       <input
//         type="text"
//         value={userInput}
//         onChange={(e) => setUserInput(e.target.value)}
//         placeholder="Type your message..."
//       />
//       <button onClick={sendMessage}>Send</button>
//     </div>
//   );
// }

// export default CropAnna4;

import React, { useState } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";

const apiKey = process.env.REACT_APP_GEMINI_API_KEY; // Set your API key in .env
const genAI = new GoogleGenerativeAI(apiKey);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
  systemInstruction:
    "Welcome to CropAnna! A digital assistant dedicated to supporting India's farmers, agricultural experts, retailers, and investors. CropAnna helps users with farming tips, market information, and access to government schemes. Use the following guidelines to train the chatbot:\nuse vanakkam. or namaskar for greetings",
});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
};

const CropAnna4 = () => {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);

  //   const handleSendMessage = async () => {
  //     if (!input) return <p>It Can't be Empty</p>;

  //     const updatedMessages = [
  //       ...messages,
  //       { role: "user", content: { parts: [input] } },
  //     ];

  //     const chatSession = model.startChat({
  //       generationConfig,
  //       history: updatedMessages, // Pass the updated messages with roles
  //     });

  //     const result = await chatSession.sendMessage(input);
  //     setMessages([...messages, { user: input, bot: result.response.text() }]);
  //     setInput("");
  //   };

  const handleSendMessage = async () => {
    if (!input) return; // Prevent sending empty messages

    // Format user's message
    const userMessage = {
      role: "user",
      content: {
        parts: [input], // Wrap input in parts array
      },
    };

    // Update messages history
    const updatedMessages = [...messages, userMessage];

    const chatSession = model.startChat({
      generationConfig,
      history: updatedMessages,
    });

    const result = await chatSession.sendMessage(input);

    // Format bot's response
    const botMessage = {
      role: "model",
      content: {
        parts: [result.response.text()], // Wrap response in parts array
      },
    };

    // Update the messages state with the new messages
    setMessages([...updatedMessages, botMessage]);
    setInput(""); // Clear the input field
  };
  return (
    <div>
      <h1>CropAnna Chatbot</h1>
      <div className="chat-window">
        {messages.map((msg, index) => (
          <div key={index}>
            <strong>You:</strong> {msg.user}
            <br />
            <strong>Bot:</strong> {msg.bot}
            <hr />
          </div>
        ))}
      </div>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type your message here..."
      />
      <button onClick={handleSendMessage}>Send</button>
    </div>
  );
};

export default CropAnna4;
