import React from "react";
// import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { Container, Typography, Grid, Box, Paper } from "@mui/material";
import "./Careers.css";
// import cropnowImage from "../../assets/images/crop.jpg";

import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import TryIcon from "@mui/icons-material/Try";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";

// import { LocalLibraryIcon,SportsHandballIcon, TryIcon, Diversity1Icon, AddModeratorIcon, SettingsAccessibilityIcon } from "@mui/icons-material"

const Careers = () => {
  return (
    <div className="nav__margin">
      <div className="career_page">
        <div className="career_hero">
          <section
            class="text-gray-600 body-font"
            className="career_hero_content"
          >
            <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
              {/* <img
                class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
                alt="hero"
                src="https://dummyimage.com/720x600"
              /> */}
              <div class="text-center lg:w-2/3 w-full">
                <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium ">
                  Welcome to Your Future with CropNow!
                </h1>
                <p class="mb-8 leading-relaxed">
                  CropNow is an innovative agri-tech startup that aims to
                  revolutionize farming practices through advanced technology
                  and sustainable solutions. We are committed to empowering
                  farmers by integrating AI, IoT, and cutting-edge research into
                  agricultural practices, making farming smarter, more
                  efficient, and eco-friendly.
                </p>
              </div>
            </div>
          </section>
        </div>

        {/* =====work culture ===== */}

        <section className="work_culture">
          <section
            class="text-gray-600 body-font"
            className="work_culture_content"
          >
            <div class="container px-5 py-24 mx-auto">
              <div class="text-center mb-20">
                <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
                  Work Culture at CropNow
                </h1>
                <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
                  At CropNow, we embrace a culture of collaboration,
                  flexibility, and innovation. Whether working from college
                  campuses or home offices, we uphold a strong sense of
                  discipline and commitment. Our focus is not on the number of
                  hours worked but on the quality of the outcomes achieved. We
                  highly value every team member's ideas, creativity, and
                  contributions to the future of agriculture.
                </p>
                <div class="flex mt-6 justify-center">
                  <div class="w-16 h-1 rounded-full bg-green-500 inline-flex"></div>
                </div>
              </div>
            </div>
          </section>
          <section
            class="text-gray-600 body-font"
            className="work_culture_bottom"
          >
            <div class="container px-5 mx-auto">
              <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="sm:w-16 sm:h-16 w-15 h-15"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                  </svg>
                </div>
                <div
                  className="culture_item"
                  class="flex-grow sm:text-left text-center mt-6 sm:mt-0"
                >
                  <h2 className="culture_item_title">Culture</h2>
                  <p>
                    Emphasis on collaboration, flexibility, and innovation in
                    our work culture.
                  </p>
                </div>
              </div>
              <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                <div class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="sm:w-16 sm:h-16 w-15 h-15"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="11" cy="11" r="6"></circle>
                    <line x1="16" y1="16" x2="21" y2="21"></line>
                  </svg>
                </div>
                <div
                  className="culture_item"
                  class="flex-grow sm:text-left text-center mt-6 sm:mt-0"
                >
                  <h2 className="culture_item_title">Quality</h2>
                  <p>
                    Commitment to quality outcomes over the number of hours
                    worked.
                  </p>
                </div>
              </div>
              <div class="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
                <div class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 flex-shrink-0">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="sm:w-16 sm:h-16 w-15 h-15"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <div
                  className="culture_item"
                  class="flex-grow sm:text-left text-center mt-6 sm:mt-0"
                >
                  <h2 className="culture_item_title">Value</h2>
                  <p>
                    Valuing each team member’s ideas and contributions to
                    advancing agriculture.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* ======== Benefits of working with us  ============  */}

        <section class="text-gray-600 body-font" className="benefit_section">
          <div class="container px-5 py-24 mx-auto">
            <div class="flex flex-col text-center w-full mb-20">
              <h1 className="benefit_title">Benefits of Working at CropNow</h1>
            </div>
            <Container>
              <Grid container spacing={2}>
                {/* Define grid items with responsive behavior */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Paper
                    elevation={1}
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      borderRadius: "25px",
                      height: "200px",
                    }}
                  >
                    <Box mb={2}>
                      <LocalLibraryIcon
                        style={{ fontSize: 40, color: "#225522" }}
                      />
                    </Box>
                    <Typography variant="h4">Learning & Development</Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Paper
                    elevation={1}
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      borderRadius: "25px",
                      height: "200px",
                    }}
                  >
                    <Box mb={2}>
                      <SportsHandballIcon
                        style={{ fontSize: 40, color: "#225522" }}
                      />
                    </Box>
                    <Typography variant="h4">
                      Shape the Future of India
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Paper
                    elevation={1}
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      borderRadius: "25px",
                      height: "200px",
                    }}
                  >
                    <Box mb={2}>
                      <TryIcon style={{ fontSize: 40, color: "#225522" }} />
                    </Box>
                    <Typography variant="h4">
                      Experience a Startup from Scratch
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Paper
                    elevation={1}
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      borderRadius: "25px",
                      height: "200px",
                    }}
                  >
                    <Box mb={2}>
                      <Diversity1Icon
                        style={{ fontSize: 40, color: "#225522" }}
                      />
                    </Box>
                    <Typography variant="h4">Expand Your Network</Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Paper
                    elevation={1}
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      borderRadius: "25px",
                      height: "200px",
                    }}
                  >
                    <Box mb={2}>
                      <AddModeratorIcon
                        style={{ fontSize: 40, color: "#225522" }}
                      />
                    </Box>
                    <Typography variant="h4">
                      Interact with Industry Experts & Professors
                    </Typography>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Paper
                    elevation={1}
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      borderRadius: "25px",
                      height: "200px",
                    }}
                  >
                    <Box mb={2}>
                      <SettingsAccessibilityIcon
                        style={{ fontSize: 40, color: "#225522" }}
                      />
                    </Box>
                    <Typography variant="h4">
                      Engage with Farmers & Solve Real-World Problems
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </div>
        </section>

        {/* ====new==== */}

        {/* =====cta ===== */}

        <section
          class="text-gray-600 body-font relative"
          className="career_cta_section"
        >
          <div
            class="container px-5 py-24 mx-auto"
            className="career_cta_section_content"
          >
            <div
              class="flex flex-col text-center w-full mb-12"
              className="career_cta_section_inner"
            >
              <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                Apply Now
              </h1>
              <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
                Join C-Force, CropNow’s exclusive 123-day internship program,
                where you will Innovate. Learn. Impact. Be part of an incredible
                journey to make agriculture smarter and more efficient, while
                shaping a better future for India’s farming community.
              </p>
            </div>

            <div class="lg:w-1/2 md:w-2/3 mx-auto">
              <div class="flex flex-wrap justify-center -m-2">
                <div class="p-2 w-full" className="apply_btn_div">
                  <a
                    href="https://forms.gle/JFuw4qtZnmWEccKYA"
                    target="_blank"
                    rel="noreferrer"
                    className="career_page_apply_btn"
                  >
                    Apply now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="footer_dummy"></section>
      </div>
    </div>
  );
};

export default Careers;
