import React from "react";
import "./Hero1.css"; // Importing the custom CSS for Hero1
// import backgroundImage from "../../assets/HeroImages/img1.jpg"; // Adjust the path as per your structure
import backgroundImage from "../../assets/HeroImages/img1.webp"; // Adjust the path as per your structure

const Hero1 = () => {
  return (
    <div
      className="hero1-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay">
        <div className="content">
          <h1 className="hero-title">CropNow</h1>
          <h1 className="hero-sub-title">For A Better India</h1>
          <div className="buttons">
            <button>About Soil</button>
            <button>About Plants</button>
            <button>Climate Status</button>
            <a href="https://solutions.cropnow.in/category/written-blogs/">
              <button>Written Blogs</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero1;
