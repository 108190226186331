import React from "react";
import ReactGA from "react-ga4";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

// import { CircularProgress, Container } from "@mui/material";
import axios from "axios";
import "./App.css";

import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Careers from "./Pages/Careers/Careers";
import Layout from "./Container/Layout/Layout";
import SignUp from "./Pages/Auth/SignUp";
import SignIn from "./Pages/Auth/SignIn";
import Forgot from "./Pages/Auth/Forgot";
import Profile from "./Pages/Profile/Profile";
import ChangePassword from "./Pages/Auth/ChangePassword";
import Hero2 from "./Container/Hero2/Hero2";
// import Cropanna from "./Pages/Cropanna/Cropanna";
import CropAnna22 from "./Pages/Cropanna/Cropanna2";
import Cropfund from "./Pages/Cropfund/Cropfund";
import CropAnna from "./Pages/Cropanna/CropAnna";
import CropAnna4 from "./Pages/Cropanna/CropAnna4";

// // Lazy load components
// const Home = lazy(() => import("./Pages/Home/Home"));
// const About = lazy(() => import("./Pages/About/About"));
// const Careers = lazy(() => import("./Pages/Careers/Careers"));
// const Layout = lazy(() => import("./Container/Layout/Layout"));

axios.defaults.withCredentials = true;
ReactGA.initialize("G-HCTXS1YJ3B");

// Send pageview with a custom path
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const App = () => {
  return (
    <div className="app">
      <div className="routes">
        <ToastContainer />
        {/* <Suspense
          fallback={
            <Container>
              <CircularProgress />
            </Container>
          }
        > */}
        <Routes>
          <Route
            path="/"
            element={
              // <Layout>
              <Home />
              // </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="/cropfund"
            element={
              <Layout>
                <Cropfund />
              </Layout>
            }
          />
          <Route
            path="/cropanna"
            element={
              <Layout>
                <CropAnna />
              </Layout>
            }
          />
          <Route
            path="/cropanna4"
            element={
              <Layout>
                <CropAnna4 />
              </Layout>
            }
          />

          <Route
            path="/signup"
            element={
              <Layout>
                <SignUp />
              </Layout>
            }
          />
          <Route
            path="/signin"
            element={
              <Layout>
                <SignIn />
              </Layout>
            }
          />
          <Route
            path="/forgot"
            element={
              <Layout>
                <Forgot />
              </Layout>
            }
          />
          <Route
            path="/change-Password"
            element={
              <Layout>
                <ChangePassword />
              </Layout>
            }
          />
          <Route
            path="/profile"
            exact
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
          <Route
            path="/careers"
            element={
              <Layout>
                <Careers />
              </Layout>
            }
          />
        </Routes>
        {/* </Suspense> */}
      </div>
    </div>
  );
};

export default App;
