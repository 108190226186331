import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Tooltip,
  MenuItem,
  Avatar,
  IconButton,
  Button,
  Typography,
  Menu,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { logout, RESET } from "../../../redux/features/auth/authSlice";

// import logo from "../../assets/logo.png";
import logo from "../../../assets/logo/CropNow_logo.png";
import Menuitems from "../MenuItems/MenuItems";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../Loader/Loader";
import "./Header1.css";

function Header1() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isLoggedIn, user } = useSelector((state) => state.auth);

  const loggingOut = async () => {
    await dispatch(logout());
    await dispatch(RESET());
    navigate("/signIn");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" color="inherit">
      {isLoading && <Loader />}
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Box
            variant="h6"
            // noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {/* <Link to="/"> */}
            <div className="logo_div">
              <img src={logo} alt="logo" />
            </div>

            {/* </Link> */}
            {/* LOGO */}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              //   size="89"
              size="large"
              //   sx={{ fontSize: "2rem" }}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              //   color="inherit"
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <Menuitems />
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
          <Box
            variant="h5"
            // noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {/* CropNow */}
            <div className="logo_div">
              <img src={logo} alt={logo} />
            </div>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              ml: "6rem",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Menuitems />
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user ? (
              isLoggedIn && (
                <>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      {/* <BiDownArrow /> */}
                      <Typography
                        variant="h5"
                        m="2"
                        p={3}
                        sx={{ display: { xs: "none", md: "block" } }}
                      >
                        <b>{user.name}</b>
                      </Typography>
                      <KeyboardArrowDownIcon />
                      <Avatar alt={user.name} src={user.photo} />
                    </IconButton>
                    {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar alt={user.name} src={user.photo} />
                    </IconButton> */}
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {/* {settings.map((setting) => ( */}
                    <MenuItem onClick={handleCloseUserMenu}>
                      <NavLink to="/profile">Profile</NavLink>
                    </MenuItem>

                    <MenuItem onClick={handleCloseUserMenu}>
                      <NavLink to="/change-Password">Change Password</NavLink>
                    </MenuItem>
                    <hr />
                    <MenuItem onClick={handleCloseUserMenu}>
                      <NavLink onClick={loggingOut}>Logout</NavLink>
                      {/* <NavLink to="/Change-Password">Logout</NavLink> */}
                    </MenuItem>
                  </Menu>
                </>
              )
            ) : (
              <>
                <Box
                  sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" } }}
                  className="nav-login-btn"
                >
                  <Button
                    size="large"
                    color="success"
                    variant="contained"
                    sx={{ padding: { xs: "2px", sm: "4px", md: "8px" } }}
                  >
                    <Link to="/SignIn" className="login_button_link">
                      SignIn / SignUp
                    </Link>
                  </Button>
                  {/* <Button
                    size="large"
                    variant="outlined"
                    px="18px"
                    sx={{
                    //   padding: { xs: "2px", sm: "4px", md: "8px" },
                    //   color: "#fff",
                    }}
                  >
                    <Link to="/login">SignIn / SignUp</Link>
                  </Button> */}
                </Box>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header1;
