import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Hero1 from "../../Container/Hero1/Hero1";
import Hero2 from "../../Container/Hero2/Hero2";
import Hero3 from "../../Container/Hero3/Hero3";
import Hero4 from "../../Container/Hero4/Hero4";
import Header from "../../Components/Header/Header";
import "./Home.css";
import { Link } from "react-router-dom";

const Home = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Header />
      <>
        <div className="home_content">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              <Hero1 />
            </Carousel.Item>
            <Carousel.Item>
              <Hero2 />
            </Carousel.Item>
            <Carousel.Item>
              <Hero4 />
            </Carousel.Item>
            <Carousel.Item>
              <Hero3 />
            </Carousel.Item>
          </Carousel>

          <div className="aibutton">
            <div className="aibutton-text">
              {/* <p>Crop Anna Coming Soon</p> */}
              <Link to="/CropAnna">
                <button></button>
              </Link>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Home;
