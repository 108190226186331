import React from "react";

import { NavLink } from "react-router-dom";
import "./MenuItems.css";

const MenuItems = () => {
  return (
    <div>
      <div className="menu-items">
        <NavLink to="/">
          <li>Home</li>
        </NavLink>

        <NavLink to="/about">
          <li>About</li>
        </NavLink>
        <NavLink to="/cropfund">
          <li>CropFund</li>
        </NavLink>
        <NavLink to="/careers">
          <li>Career</li>
        </NavLink>
      </div>
    </div>
  );
};

export default MenuItems;
