import React from "react";
import "./Hero2.css";
import backgroundImage from "../../assets/HeroImages/img2.jpg"; // Adjust the path as per your structure
import { FaLink, FaHandHoldingHeart, FaCameraRetro } from "react-icons/fa"; // Import necessary icons
import { Card } from "react-bootstrap"; // Import Card from React Bootstrap

const Hero2 = () => {
  return (
    <div
      className="hero2-container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay">
        <div className="hero2_content">
          <h1 style={{ color: "#fff", textAlign: "center" }}>
            CropNow's Community
          </h1>

          <div className="community-boxes d-flex justify-content-around">
            <Card
              className="hero2box text-center hero2_responsive_card"
              style={{ margin: "10px", borderRadius: "8px" }}
            >
              <Card.Body className="d-flex flex-column align-items-center">
                <FaLink style={{ fontSize: "40px", color: "#4CAF50" }} />
                <Card.Title className="mt-3 hero2_card_title ">
                  CropLink
                </Card.Title>
                <p className="hero2_card_comingsoon">Coming Soon</p>
                <Card.Text className="hero2_card_text">
                  Connect directly with farmers and discover fresh,
                  locally-sourced produce.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="hero2box text-center hero2_responsive_card"
              style={{ margin: "10px", borderRadius: "8px" }}
            >
              <Card.Body className="d-flex flex-column align-items-center">
                <FaHandHoldingHeart
                  style={{ fontSize: "40px", color: "#4CAF50" }}
                />
                <Card.Title className="mt-3 hero2_card_title ">
                  Crop Fund
                </Card.Title>
                <p className="hero2_card_comingsoon">Coming Soon</p>
                <Card.Text className="hero2_card_text">
                  Contribute and become a hero! We offer people the unique
                  opportunity to support farmers.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card
              className="hero2box text-center hero2_responsive_card"
              style={{ margin: "10px", borderRadius: "8px" }}
            >
              <Card.Body className="d-flex flex-column align-items-center">
                <FaCameraRetro style={{ fontSize: "40px", color: "#4CAF50" }} />
                <Card.Title className="mt-3 hero2_card_title ">
                  CropGram
                </Card.Title>
                <p className="hero2_card_comingsoon">Coming Soon</p>
                <Card.Text className="hero2_card_text">
                  The platform where users can post and share their issues and
                  achievements.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero2;
