import React, { useState } from "react";
import "./Cropanna.css";
import { loaderspin } from "../../Components/Loader/Loader"; // Ensure loaderspin is a spinner component
import { BASE_API_URL } from "../../Components/Utils";
import CropAnnaImg from "../../assets/images/annabg.png";

const CropAnna = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userInput, setUserInput] = useState("");

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const userMessage = { role: "user", content: userInput };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoading(true);
    setUserInput("");

    try {
      const response = await fetch(`${BASE_API_URL}/api/chat/azureai`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: [...messages, userMessage],
        }),
      });

      const result = await response.json();
      console.log("resuts ", result);
      if (response.ok) {
        const assistantMessage = {
          role: "assistant",
          content: result.content || "No Response Content Available",
        };
        setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      } else {
        throw new Error(result.message || "Error fetching response");
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  console.log("msg", messages);
  return (
    <div className="chat-container">
      {/* <h1 className="chat-title">CropAnna Chatbot</h1> */}
      <div className="cropanna_imgholder">
        <img src={CropAnnaImg} alt="cropanna" />
      </div>
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            <strong>{msg.role === "user" ? "You:" : "CropAnna:"}</strong>
            {msg.content}
          </div>
        ))}
        {loading && (
          <div className="loading-message">
            {loaderspin} {/* Your loading spinner component */}
            <span>Processing your request...</span>
          </div>
        )}
      </div>
      {error && <div className="error">Error: {error.message}</div>}
      <form className="input-form" onSubmit={handleSubmit}>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          className="input-field"
          placeholder="Ask a question..."
          required
        />
        <button type="submit" className="send-button" disabled={loading}>
          {loading ? "Sending..." : "Send"}
        </button>
      </form>
    </div>
  );
};

export default CropAnna;
