import React from "react";
import "./Hero3.css";
import backgroundImage from "../../assets/HeroImages/img3.jpg"; // Adjust the path as per your structure
import usericon from "../../assets/user/usericon.jpg";
const contributors = [
  {
    name: "John Doe",
    image: usericon,
    description:
      "A passionate agricultural specialist with over 15 years of experience in sustainable farming practices.",
  },
  {
    name: "Jane Smith",
    image: usericon,
    description:
      "Leading researcher in crop genetics, committed to improving crop yield and resilience through innovative solutions.",
  },
  {
    name: "Mohammed Ali",
    image: usericon,
    description:
      "Dedicated farmer who has successfully implemented organic farming techniques across 200 acres of land.",
  },
  {
    name: "Sophia Wang",
    image: usericon,
    description:
      "Expert in agricultural economics, providing valuable insights into market trends and sustainable development.",
  },
  {
    name: "Sophia Wang",
    image: usericon,
    description:
      "Expert in agricultural economics, providing valuable insights into market trends and sustainable development.",
  },
  {
    name: "Sophia Wang",
    image: usericon,
    description:
      "Expert in agricultural economics, providing valuable insights into market trends and sustainable development.",
  },
  {
    name: "Sophia Wang",
    image: usericon,
    description:
      "Expert in agricultural economics, providing valuable insights into market trends and sustainable development.",
  },
];

// console.log(contributors.image);

const Hero3 = () => {
  return (
    <div
      className="hero3__container"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="overlay">
        <div className="content">
          <h1 className="hero3-title">Our Top Contributors</h1>
          <div className="contributors__grid">
            {contributors.map((contributor, index) => (
              <div key={index} className="contributor__item">
                <img
                  src={`${contributor.image}`}
                  alt={contributor.name}
                  className="contributor__image"
                />
                <h3 className="contributor__name">{contributor.name}</h3>
                <p className="contributor__description">
                  {contributor.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero3;
