import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { BsCheck2All } from "react-icons/bs";
import { TiUserAddOutline } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { validateEmail } from "../../redux/features/auth/authService";
import { useDispatch, useSelector } from "react-redux";
import {
  register,
  RESET,
  sendVerificationEmail,
} from "../../redux/features/auth/authSlice";

import { Button, Card } from "@mui/material";
import PasswordInput from "../../Components/PasswordInput/PasswordInput";

import "./Auth.css";
import Loader from "../../Components/Loader/Loader";

const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  password2: "",
};

const SignUp = () => {
  const [formData, setFormData] = useState(initialState);
  const { name, email, phone, user_type, password, password2 } = formData;

  const [phoneValid, setPhoneValid] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading, isLoggedIn, isSuccess } = useSelector(
    (state) => state.auth
  );

  const [uCase, setUCase] = useState(false);
  const [num, setNum] = useState(false);
  const [sChar, setSChar] = useState(false);
  const [passLength, setPassLength] = useState(false);

  const timesIcon = <FaTimes color="red" size={15} />;
  const checkIcon = <BsCheck2All color="green" size={15} />;

  const switchIcon = (condition) => {
    if (condition) {
      return checkIcon;
    }
    return timesIcon;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate phone number
    if (name === "phone") {
      if (/^\d{10}$/.test(value)) {
        setPhoneValid(true);
      } else {
        setPhoneValid(false);
      }
    }
  };

  useEffect(() => {
    // Check Lower and Uppercase
    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      setUCase(true);
    } else {
      setUCase(false);
    }
    // Check for numbers
    if (password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }
    // Check for special character
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      setSChar(true);
    } else {
      setSChar(false);
    }
    // Check for PASSWORD LENGTH
    if (password.length > 5) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password]);

  const registerUser = async (e) => {
    e.preventDefault();

    if (!name || !email || !user_type || !password) {
      return toast.error("All fields are required");
    }
    if (password.length < 6) {
      return toast.error("Password must be up to 6 characters");
    }
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email");
    }
    if (password !== password2) {
      return toast.error("Passwords do not match");
    }

    const userData = {
      name,
      email,
      phone,
      password,
      user_type,
    };

    // console.log(userData);
    await dispatch(register(userData));
    await dispatch(sendVerificationEmail());
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      navigate("/profile");
    }

    dispatch(RESET());
  }, [isLoggedIn, isSuccess, dispatch, navigate]);

  return (
    <div className="container auth">
      {isLoading && <Loader />}
      <Card>
        <div className="signup_form form">
          <div className="--flex-center">
            <TiUserAddOutline size={35} color="#999" />
          </div>
          <h2>Sign-Up</h2>

          {/* <form> */}
          <form onSubmit={registerUser}>
            <input
              type="text"
              placeholder="Name"
              required
              name="name"
              value={name}
              onChange={handleInputChange}
            />
            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              value={email}
              onChange={handleInputChange}
            />
            <input
              type="tel"
              placeholder="Phone"
              required
              name="phone"
              value={phone}
              onChange={handleInputChange}
              pattern="\d{10}"
              title="Phone number must be exactly 10 digits"
            />
            {!phoneValid && phone.length > 0 && (
              <span className="error-message">
                Phone number must be exactly 10 digits
              </span>
            )}

            {/* <input
              type="text"
              placeholder="User Type"
              required
              name="user_type"
              value={user_type}
              onChange={handleInputChange}
            /> */}

            <select
              required
              name="user_type"
              value={user_type}
              onChange={handleInputChange}
            >
              <option value="" disabled>
                Select User Type
              </option>
              <option value="Farmer">Farmer</option>
              <option value="Agricultural Specialist">
                Agricultural Specialist
              </option>
              <option value="Investor">Investor</option>
              <option value="Student">Student</option>
              <option value="Government Organization">
                Government Organization
              </option>
              <option value="Retailer">Retailer</option>
              <option value="Other">Other</option>
            </select>

            <PasswordInput
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleInputChange}
            />
            <PasswordInput
              placeholder="Confirm Password"
              name="password2"
              value={password2}
              onChange={handleInputChange}
              onPaste={(e) => {
                e.preventDefault();
                toast.error("Cannot paste into input field");
                return false;
              }}
            />

            {/* Password Strength */}
            <Card>
              <ul className="form-list">
                <li>
                  <span className="indicator">
                    {switchIcon(uCase)}
                    &nbsp; Lowercase & Uppercase
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {switchIcon(num)}
                    &nbsp; Number (0-9)
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {switchIcon(sChar)}
                    &nbsp; Special Character (!@#$%^&*)
                  </span>
                </li>
                <li>
                  <span className="indicator">
                    {switchIcon(passLength)}
                    &nbsp; At least 6 Character
                  </span>
                </li>
              </ul>
            </Card>

            <button type="submit" className="--btn --btn-primary --btn-block">
              Register
            </button>
          </form>

          <span className="register">
            <Link to="/">Home</Link>
            <p> &nbsp; Already have an account? &nbsp;</p>
            <Button color="primary" variant="outlined">
              <Link to="/signIn">Login</Link>
            </Button>
          </span>
        </div>
      </Card>
    </div>
  );
};

export default SignUp;
