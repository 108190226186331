import React from "react";

import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import "./Footer.css";
import footer_logo from "../../assets/logo/CropNow_icon.png";

const Footer = () => {
  return (
    <div className="footer-section">
      <footer className="footer-content">
        <div className="container">
          <div className="footer-content-items row">
            <div className="footer-item-col">
              <nav>
                <Link to="/">
                  <img src={footer_logo} loading="eager" alt="logo" />
                </Link>
                <div className="footer-item-col__content">
                  {/* <p className="mt-2 text-sm text-gray-500">
                    #D.No : 27-8-202/C2,ground Floor Near Z.P.H School,
                    Muddireddypalli, Hindupur -515200
                  </p> */}
                  <p className="mt-2 text-sm text-gray-500">
                    Conact : +91 914749525
                  </p>
                  <p className="mt-2 text-sm text-gray-500">
                    Mail : cropnow.contact@gmail.com
                  </p>
                </div>
              </nav>
            </div>

            {/* <div className="footer-item-col">
              <h4 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                Useful Link
              </h4>
              <nav className="list-none mb-10">
                <Link to="/">
                  <li>Home</li>
                </Link>
                <Link to="/Terms-Conditions">
                  <li>Terms & Conditions</li>
                </Link>
               <Link to="/">
                  <li>Return Policy</li>
                </Link>
              </nav> 
            </div> */}

            <div className="footer-item-col">
              <h4 className="title-font font-medium text-gray-900 tracking-widest text-sm mb-3">
                Quick Link
              </h4>
              <nav className="list-none mb-10">
                <Link to="/">
                  <li>Home</li>
                </Link>
                <Link to="/about">
                  <li>About</li>
                </Link>
                <Link to="/careers">
                  <li>Careers</li>
                </Link>
                {/* <Link to="/contact">
                  <li>Contact</li>
                </Link> */}
              </nav>
            </div>
          </div>
        </div>

        <hr />

        <div className="footer__copyright">
          {/* <div className="container "> */}
          <div className="site">
            <p>© 2024 CropNow —</p>
          </div>
          <div className="development">
            {/* <p>
              Designed and Developed by
              <span>
                <strong>
                  <a
                    href="https://teksoft.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    TekSoft
                  </a>
                </strong>
              </span>
            </p> */}
          </div>
          {/* </div> */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
