// import { CircularProgress } from "@mui/material";
import React from "react";
// import Header1 from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Header1 from "../../Components/Header/Header1/Header1";
// import Headermui from "../../Components/Header/Header1/Headermui";

// const Footer = lazy(() => import("../Footer/Footer"));

const Layout = ({ children }) => {
  return (
    <div>
      {/* <Header1 /> */}
      {/* <Header1 /> */}
      <Header1 />
      {/* <Headermui /> */}
      <div className="--pad" style={{ minHeight: "80vh" }}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
