// export const BASE_API_URL = "http://localhost:9000";
// export const BASE_API_URL = "https://cnbc.onrender.com";
export const BASE_API_URL =
  "https://cnbapp-gah4f9fuevdkh7ad.centralindia-01.azurewebsites.net/";

export const fetchData = async (url, options) => {
  const res = await fetch(url, options);
  const data = await res.json();
  return data;
};
export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};
// export const REQ_ORDER_API_URL = `${BASE_API_URL}/api/orders`;

// export const SUG_API_URL = `${BASE_API_URL}/api/requests`;
export const REACT_APP_CLOUD_NAME = "nekaaramitrafiles";
export const REACT_APP_UPLOAD_PRESET = "NekaaramitraImages";
export const REACT_APP_IMAGE_PATH =
  "https://api.cloudinary.com/v1_1/nekaaramitrafiles/image/upload";

// REACT_APP_CLOUD_NAME=nekaaramitrafiles
// REACT_APP_UPLOAD_PRESET=NekaaramitraImages
// REACT_APP_IMAGE_PATH="https://api.cloudinary.com/v1_1/nekaaramitrafiles/image/upload"
