import React from "react";
// import Container from "react-bootstrap/Container";
import { Container, Row, Box, Col, Card } from "react-bootstrap";
import {
  FaBook,
  FaComments,
  FaDollarSign,
  FaTools,
  FaStore,
  FaUserGraduate,
} from "react-icons/fa"; // Use appropriate icons

import "./About.css";

import cropnowImage from "../../assets/images/crop.jpg";

const About = () => {
  return (
    <main className="nav__margin">
      <div className="about__content">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <h1 className="title_left">About CropNow</h1>
              <p>
                CropNow is dedicated to revolutionizing agriculture by
                empowering farmers with advanced technology, education, and
                community support. Our mission is to enhance productivity,
                foster innovation, and ensure sustainable farming practices
                across India.
              </p>
              <h1 className="title_left">Our Mission</h1>
              <p>
                At CropNow, we aim to improve the lives of farmers by providing
                them with the tools and knowledge necessary to thrive in the
                modern agricultural landscape. We believe that with the right
                support, every farmer can achieve higher productivity, better
                crop management, and increased financial stability.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <img className="img-fluid" src={cropnowImage} alt="CropNow" />
            </Col>
          </Row>
        </Container>

        <div className="purpose">
          <Container>
            <h3 className="purpose_title">Purpose of CropNow</h3>
            <div className="purpose_content">
              <p className="purpose_content_para">
                The primary purpose of CropNow is to solve the myriad challenges
                faced by Indian farmers, providing them with the necessary
                education, resources, and support to enhance their agricultural
                practices and livelihoods. Our platform also aims to inspire
                students and young professionals to embrace farming, making
                agricultural activities more accessible and manageable for all.
                Additionally, CropNow seeks to gather funds to support
                financially struggling farmers, ensuring they have the means to
                succeed.
              </p>
            </div>

            <Container>
              <Row className="g-4">
                {/* Educational Empowerment */}
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Card
                    className="text-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <FaBook style={{ fontSize: 50, color: "green" }} />
                      <Card.Title
                        className="mt-2"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Educational Empowerment
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Community Support and Engagement */}
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Card
                    className="text-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <FaComments style={{ fontSize: 50, color: "green" }} />
                      <Card.Title
                        className="mt-2"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Community Support and Engagement
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Financial Assistance */}
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Card
                    className="text-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <FaDollarSign style={{ fontSize: 50, color: "green" }} />
                      <Card.Title
                        className="mt-2"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Financial Assistance
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Technological Integration */}
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Card
                    className="text-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <FaTools style={{ fontSize: 50, color: "green" }} />
                      <Card.Title
                        className="mt-2"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Technological Integration
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Market Access and Information */}
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Card
                    className="text-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <FaStore style={{ fontSize: 50, color: "green" }} />
                      <Card.Title
                        className="mt-2"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Market Access and Information
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Youth and Student Engagement */}
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Card
                    className="text-center"
                    style={{ borderRadius: "25px" }}
                  >
                    <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                      <FaUserGraduate
                        style={{ fontSize: 50, color: "green" }}
                      />
                      <Card.Title
                        className="mt-2"
                        style={{ fontSize: "1.5rem" }}
                      >
                        Youth and Student Engagement
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Container>
        </div>
      </div>
    </main>
  );
};

export default About;
