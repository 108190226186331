import React, { useState } from "react";
import "./Cropfund.css";
import happyFarmersImage from "../../assets/images/farmercrop.jpg"; // Adjust path as needed

const quotes = [
  "Empower farmers to feed the world.",
  "Your support can change lives and cultivate hope.",
  "Together, we can grow a sustainable future.",
  "Small contributions can lead to great changes.",
  "Invest in farmers today for a better tomorrow.",
];

const Cropfund = () => {
  const [customAmount, setCustomAmount] = useState("");

  const handleDonate = (amount) => {
    // Handle donation logic here (e.g., API call)
    alert(`Thank you for your donation of ₹${amount}!`);
  };

  return (
    <div
      className="cropfund__container"
      style={{ backgroundImage: `url(${happyFarmersImage})` }}
    >
      <div className="overlay">
        <div className="quote-section">
          <p>
            Your support empowers farmers to thrive, nurturing both our land and
            our future. Together, let's sow the seeds of hope and harvest a
            better tomorrow!
          </p>
          <h2>{quotes[Math.floor(Math.random() * quotes.length)]}</h2>
        </div>
        <div className="donation-section">
          <h2>Make a Donation</h2>
          <div className="button-group">
            <button onClick={() => handleDonate(35)}>₹35</button>
            <button onClick={() => handleDonate(50)}>₹50</button>
            <button onClick={() => handleDonate(100)}>₹100</button>
            <button onClick={() => handleDonate(500)}>₹500</button>
          </div>
          <div className="custom-entry">
            <input
              type="number"
              value={customAmount}
              onChange={(e) => setCustomAmount(e.target.value)}
              placeholder="Enter custom amount"
            />
            <button onClick={() => handleDonate(customAmount)}>Donate</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cropfund;
